import React from "react";
var __jsx = React.createElement;
import FooterListArea from "./FooterListArea";
import Languages from "./Languages/languages";
import { SocialMedia } from "./SocialMedia";
import { Sidebar } from "./Sidebar";
import { Tooltip } from 'antd';
import 'antd/dist/antd.css';
import dayjs from 'dayjs';
import { StyledExchangeFooterWrapper, StyledExchangeFooter, StyledExchangeFooterMain, StyledExchangeFooterApply, StyledExchangeFooterSection, StyledFooterSubscribe, StyledFooterSubscribeForm } from "./styled";
import { useTranslation } from 'react-i18next';
import { useBreakpoint, useGA } from "../../hooks";
import { useRouter } from 'next/router';
import { useLimitOrderRouter } from "../../hooks/useLimitOrderRouter";
import IconTokenlon from "../../assets/images/tokenlon-header-logo.svg";
import RefIconCoinGecko from "../../assets/images/reflink-coingecko.svg";
import RefIconArbitrum from "../../assets/images/reflink-arbitrum.svg";
import RefIconUnstoppable from "../../assets/images/reflink-unstoppable.svg";
import RefIconImToken from "../../assets/images/reflink-imtoken.svg";
import RefIconCMC from "../../assets/images/reflink-cmc.svg";
import RefIconImKey from "../../assets/images/reflink-imkey.png";
import { useModalOpened, useModalToggle } from "../../state/application/hooks";
import { ApplicationModal } from "../../state/application/reducer";
import { RouterLink } from "../RouterLink";
import Subscribe from "../../assets/images/subscribe.svg";
import Email from "../../assets/images/email.png";

var FriendshipLinks = function FriendshipLinks() {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t,
      i18n = _useTranslation.i18n;

  var lang = i18n.resolvedLanguage === 'zh' ? 'zh-cn' : 'en-us';
  var Friendships = [{
    title: 'imToken',
    href: 'https://token.im',
    icon: RefIconImToken
  }, {
    title: 'imKey',
    href: "https://imkey.im?locale=".concat(lang),
    icon: RefIconImKey.src
  }, {
    title: 'CoinGecko',
    href: 'https://www.coingecko.com',
    icon: RefIconCoinGecko
  }, {
    title: 'CoinMarketCap',
    href: 'https://coinmarketcap.com',
    icon: RefIconCMC
  }, {
    title: 'Arbitrum',
    href: 'https://portal.arbitrum.one',
    icon: RefIconArbitrum
  }, {
    title: 'Unstoppable',
    href: 'https://unstoppabledomains.com/partners',
    icon: RefIconUnstoppable
  }];

  var _useGA = useGA(),
      trackElClick = _useGA.trackElClick;

  return __jsx(React.Fragment, null, Friendships.map(function (item) {
    return __jsx(Tooltip, {
      title: item.title,
      key: item.title
    }, __jsx("a", {
      href: item.href,
      target: "_blank",
      title: item.title,
      "data-trackid": "webtl_bottom_external",
      "data-trackdata": JSON.stringify({
        url: item.href,
        title: item.title
      }),
      onClick: trackElClick
    }, __jsx("img", {
      className: "ref-link",
      src: item.icon,
      alt: item.title
    })));
  }));
};

export var Footer = function Footer() {
  var _useTranslation2 = useTranslation(),
      t = _useTranslation2.t,
      i18n = _useTranslation2.i18n;

  var lang = i18n.resolvedLanguage === 'zh' ? 'zh-cn' : 'en-us';

  var _useBreakpoint = useBreakpoint(),
      isBreakPointLG = _useBreakpoint.isBreakPointLG;

  var sidebarOpened = useModalOpened(ApplicationModal.MOBILE_SIDEBAR);
  var toggleSidebar = useModalToggle(ApplicationModal.MOBILE_SIDEBAR, {
    stack: true
  });

  var _useRouter = useRouter(),
      route = _useRouter.route,
      asPath = _useRouter.asPath;

  var isLimitOrder = useLimitOrderRouter();

  var _useGA2 = useGA(),
      trackClick = _useGA2.trackClick;

  var isInstantRouter = asPath === '/instant' || /^\/exchange/g.test(route);
  var showLiteFooter = isLimitOrder || isInstantRouter;

  if (isBreakPointLG) {
    return __jsx(Sidebar, {
      opened: sidebarOpened,
      handleClose: toggleSidebar
    });
  }

  return __jsx(StyledExchangeFooterWrapper, {
    "data-value": "StyledExchangeFooterWrapper"
  }, !showLiteFooter && __jsx(StyledFooterSubscribe, null, __jsx("h3", null, t('subscribe_newsletter')), __jsx(StyledFooterSubscribeForm, {
    action: "https://9b794b26.sibforms.com/serve/MUIEAH7YOhtoj_Ryn-3_Q7AWCOUQCtYgqoNnkwxggcfyQVCu8VfMlF9gy8LFPTqL_W53zVgFc-KDhE38rAY8FzZ02JZWMwkwVBLs0NDqUS7QQqt7eW3iJmDu2ktDxGhOAEFdQjSKBQo1aHO2rfQG1GEMgHKV7x45DpW4LjGIPrwJKl2WmOm9Lu6rvOL5fl5VZkIYMlHflD0gkpww",
    method: "post",
    onSubmit: function onSubmit(e) {
      return trackClick('webtl_bottom_email', {
        title: e.target[0].value
      });
    },
    target: "_blank"
  }, __jsx("input", {
    className: "input",
    type: "email",
    name: "EMAIL",
    id: "mce-EMAIL",
    placeholder: t('enter_email_address'),
    required: true
  }), __jsx("img", {
    className: "email",
    src: Email.src,
    alt: "email"
  }), __jsx("img", {
    className: "icon",
    src: Subscribe,
    alt: "Subscribe button"
  }), __jsx("input", {
    className: "submit",
    type: "submit"
  }))), __jsx(StyledExchangeFooter, {
    "data-value": "StyledExchangeFooter"
  }, __jsx(StyledExchangeFooterMain, {
    "data-value": "StyledExchangeFooterMain"
  }, __jsx(StyledExchangeFooterSection, null, __jsx(RouterLink, {
    href: "/",
    className: "logo-link"
  }, __jsx("img", {
    src: IconTokenlon,
    className: "tokenlon-header-logo"
  })), __jsx(Languages, null)), __jsx(FooterListArea, null))), __jsx(StyledExchangeFooterApply, {
    className: showLiteFooter && 'footer-lite'
  }, __jsx("p", {
    className: "ref-links"
  }, __jsx("span", null, t('links')), __jsx(FriendshipLinks, null)), __jsx("div", {
    className: "footer-copyright"
  }, __jsx("p", null, "\xA9 2019 - ", dayjs().year(), " DIGITAL ERA GLOBAL LIMITED. All rights reserved.", ' ', __jsx("a", {
    target: "_blank",
    href: "/tos",
    style: {
      color: '#5F4DBC'
    }
  }, "Terms"), __jsx("span", {
    className: "emails"
  }, ' | ', __jsx("a", {
    target: "_blank",
    href: "mailto:support@tokenlon.im"
  }, "support@tokenlon.im"))), __jsx(SocialMedia, null))));
};
export default Footer;