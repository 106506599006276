import { LOCALES } from "../locales";
export var setupBeacon = function setupBeacon(locale) {
  if (!window || !window.Beacon) {
    console.warn('helpScout init failed');
    return;
  }

  var id = locale === LOCALES.EN ? 'f0234c82-9931-4184-ae0a-9f08cb0a671b' : 'd7c4d4a6-3967-4198-9622-281736036e6d';
  window.Beacon('config', {
    hideFABOnMobile: true
  });
  var info = window.Beacon('info');

  if (!info) {
    window.Beacon('init', id);
  }

  if (info && info.beaconId !== id) {
    window.Beacon('destroy');
    window.Beacon('init', id);
  }
};