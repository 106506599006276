import _extends from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
var __jsx = React.createElement;
import Link from 'next/link';
import styled from 'styled-components';
export var MainNavItem = function MainNavItem(_ref) {
  var pathname = _ref.pathname,
      navText = _ref.navText,
      onMouseOver = _ref.onMouseOver,
      sub = _ref.sub,
      onMouseLeave = _ref.onMouseLeave,
      onClick = _ref.onClick,
      className = _ref.className,
      dataTestid = _ref['data-testid'],
      dataTrackId = _ref['data-trackid'];

  var noop = function noop() {};

  var newTabProps = pathname && pathname.startsWith('http') ? {
    target: '_blank',
    rel: 'noopener noreferrer'
  } : {};
  return __jsx(StyledNavItem, {
    className: className,
    onMouseOver: !sub ? onMouseOver : noop,
    onMouseLeave: !sub ? onMouseLeave : noop,
    "data-testid": dataTestid
  }, pathname ? __jsx(Link, {
    href: pathname,
    passHref: true
  }, __jsx("a", _extends({
    onClick: onClick || noop,
    "data-trackid": dataTrackId
  }, newTabProps), navText)) : __jsx("span", {
    onClick: onClick || noop,
    "data-trackid": dataTrackId
  }, navText));
};
var StyledNavItem = styled.li.withConfig({
  displayName: "StyledNavItem",
  componentId: "sc-1g368hm-0"
})(["a{color:inherit;}a:hover{color:inherit;}"]);