import _slicedToArray from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React from "react";
var __jsx = React.createElement;
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from "../../../hooks";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { LOCALES } from "../../../locales";
import { StyledLanguageArea, StyledLanguageAreaSelector, StyledLanguagePicker, StyledOptionList } from "./styled";
import IconChangeLanguage from "../assets/tokenlon-change-language.svg";

var Languages = function Languages() {
  var ref = useRef();

  var _useState = useState(false),
      visible = _useState[0],
      setVisible = _useState[1];

  var _useLocalStorage = useLocalStorage('lang', LOCALES.EN),
      _useLocalStorage2 = _slicedToArray(_useLocalStorage, 2),
      _ = _useLocalStorage2[0],
      setValue = _useLocalStorage2[1];

  var _useTranslation = useTranslation(),
      i18n = _useTranslation.i18n;

  var isZh = i18n.resolvedLanguage === LOCALES.ZH;

  var clickHandler = function clickHandler(event, lang) {
    event.preventDefault();
    setValue(lang);
    i18n.changeLanguage(lang);
    setVisible(false);
  };

  useClickAway(ref, function () {
    return setVisible(false);
  });
  return __jsx(StyledLanguageArea, {
    ref: ref,
    "data-value": "StyledLanguageArea"
  }, __jsx(StyledLanguageAreaSelector, {
    onClick: function onClick() {
      return setVisible(function (last) {
        return !last;
      });
    }
  }, __jsx("img", {
    className: "language-icon",
    src: IconChangeLanguage
  }), __jsx("p", {
    id: "current-language",
    className: "current-language"
  }, isZh ? '简体中文' : 'English'), __jsx("div", {
    className: "trangle"
  })), __jsx(StyledLanguagePicker, {
    className: visible ? 'visible' : '',
    "data-value": "StyledLanguagePicker"
  }, __jsx(StyledOptionList, null, __jsx("li", null, __jsx("a", {
    "data-language": "zh-CN",
    onClick: function onClick(e) {
      return clickHandler(e, LOCALES.ZH);
    },
    className: isZh ? 'selected' : ''
  }, __jsx("span", null, "\u7B80\u4F53\u4E2D\u6587"))), __jsx("li", null, __jsx("a", {
    "data-language": "en-US",
    onClick: function onClick(e) {
      return clickHandler(e, LOCALES.EN);
    },
    className: !isZh ? 'selected' : ''
  }, __jsx("span", null, "English"))))));
};

export default Languages;