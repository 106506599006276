import _taggedTemplateLiteral from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from 'styled-components';
export var StyledAccount = styled.div.withConfig({
  displayName: "StyledAccount",
  componentId: "sc-3aq7q3-0"
})(["display:inline-flex;justify-content:space-around;align-items:center;flex-wrap:nowrap;background:#fafafc;border:1px solid #eff0fa;box-sizing:border-box;border-radius:28px;padding:5.5px 8px 5.5px 8px;min-width:136px;height:40px;cursor:pointer;"]);
export var StyledAccountAvatar = styled.img.withConfig({
  displayName: "StyledAccountAvatar",
  componentId: "sc-3aq7q3-1"
})(["width:33px;height:33px;margin-right:8px;", " ", " ", ""], function (_ref) {
  var rounded = _ref.rounded;
  return rounded && "\n      border-radius: 50%;\n  ";
}, function (_ref2) {
  var size = _ref2.size;
  return size && "width: ".concat(size, "px; height: ").concat(size, "px;");
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.media.sm(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    margin-right: 4px;\n  "])));
});
export var StyledAccountInfo = styled.div.withConfig({
  displayName: "StyledAccountInfo",
  componentId: "sc-3aq7q3-2"
})(["display:flex;flex-direction:column;justify-content:center;.address{color:#5f4dbc;font-weight:500;font-size:11px;line-height:15px;}.balance{font-weight:500;font-size:11px;line-height:15px;color:#9598ab;white-space:nowrap;}"]);