import React from "react";
var __jsx = React.createElement;
import styled from 'styled-components';
import { useBreakpoint } from "../../hooks";
import { useHelpScout } from "../../hooks/useHelpScout";
import { ReactComponent as HeadphonesIcon } from "../../assets/images/headphones.svg";
export var HelpScout = function HelpScout() {
  var _useBreakpoint = useBreakpoint(),
      isMobile = _useBreakpoint.isMobile;

  var toggle = useHelpScout();
  if (!isMobile) return null;
  return __jsx(StyledSMHelpScout, {
    onClick: function onClick() {
      return toggle();
    }
  }, __jsx(HeadphonesIcon, null));
};
var StyledSMHelpScout = styled.div.withConfig({
  displayName: "StyledSMHelpScout",
  componentId: "sc-2rjwg2-0"
})(["display:flex;justify-content:center;align-items:center;width:40px;height:40px;border:1px solid #eff0fa;border-radius:28px;background:#fafafc;cursor:pointer;"]);