import React from "react";
var __jsx = React.createElement;
import IconClose from "../../../assets/images/close.svg";
import { StyledSidebarWrapper, StyledSidebarMask, StyledSidebar } from "./styled";

var SidebarWrap = function SidebarWrap(_ref) {
  var opened = _ref.opened,
      handleClose = _ref.handleClose,
      applyClassName = _ref.applyClassName,
      children = _ref.children;
  return __jsx(StyledSidebarWrapper, {
    className: opened ? 'opened' : ''
  }, __jsx(StyledSidebarMask, {
    onClick: handleClose,
    className: "sidebar-mask"
  }), __jsx(StyledSidebar, {
    className: "sidebar ".concat(applyClassName ? applyClassName : '')
  }, __jsx("img", {
    src: IconClose,
    className: "close-icon",
    onClick: handleClose
  }), children));
};

export default SidebarWrap;