import _taggedTemplateLiteral from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from 'styled-components';
export var StyledBar = styled.div.withConfig({
  displayName: "StyledBar",
  componentId: "sc-1emmdy-0"
})(["display:flex;align-items:center;justify-content:space-between;background:#e6f4fc;border-radius:8px;padding:16px 20px;width:100%;cursor:pointer;transition:opacity 0.3s ease-in-out;"]);
export var StyledTitleWrapper = styled.div.withConfig({
  displayName: "StyledTitleWrapper",
  componentId: "sc-1emmdy-1"
})(["display:flex;align-items:flex-start;gap:8px;color:#098de6;font-size:14px;font-weight:400;line-height:16px;"]);
export var StyledLink = styled.a.withConfig({
  displayName: "StyledLink",
  componentId: "sc-1emmdy-2"
})(["color:#777a8c;font-size:12px;font-weight:500;cursor:pointer;:hover{color:#5f4dbc;}", ""], function (_ref) {
  var theme = _ref.theme;
  return theme.media.sm(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    display: inline-block;\n  "])));
});
export var StyledIcon = styled.img.withConfig({
  displayName: "StyledIcon",
  componentId: "sc-1emmdy-3"
})(["width:16px;height:16px;"]);
export var StyledCloseIcon = styled(StyledIcon).withConfig({
  displayName: "StyledCloseIcon",
  componentId: "sc-1emmdy-4"
})(["cursor:pointer;"]);