import _taggedTemplateLiteral from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import { motion } from 'framer-motion';
import styled from 'styled-components';
export var StyledNetworkMessage = styled(motion.div).withConfig({
  displayName: "StyledNetworkMessage",
  componentId: "sc-drnkcr-0"
})(["width:100vw;background:#aeb2d1;display:flex;align-items:center;justify-content:center;color:#ffffff;font-weight:400;font-size:14px;line-height:24px;padding:10px;button{margin-left:10px;padding:4px 12px;color:#8992b1;font-weight:500;font-size:14px;background:#ffffff;border-radius:29px;border:none;cursor:pointer;white-space:nowrap;&:hover{color:#5f4dbc;}}", ""], function (_ref) {
  var theme = _ref.theme;
  return theme.media.sm(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    justify-content: space-between;\n  "])));
});